.grid-input-width {
    width: 100px;
}

.accordion-background {
    background-color: #F9FDFF;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.dark .accordion-background{
    background-color: #11121B;
}