.ag-theme-quartz {
    --ag-foreground-color: var(--io-gray-66);
    --ag-background-color: var(--io-white);
    --ag-header-foreground-color: var(--io-black);
    --ag-header-background-color: var(--io-gray-e9);
    --ag-header-column-resize-handle-color: var(--io-black);
   --ag-row-border-color: var(--io-gray-e9);
  
   --ag-icon-image-aggregation: url("https://www.ag-grid.com/example-assets/svg-icons/aggregation.svg");
   --ag-icon-image-arrows: url("https://www.ag-grid.com/example-assets/svg-icons/arrows.svg");
   --ag-icon-image-asc: url("https://www.ag-grid.com/example-assets/svg-icons/asc.svg");
  
  }
  html.dark .ag-theme-quartz-dark {
      --ag-foreground-color: #8A8B91;
      --ag-background-color: var(--io-black-15);
      --ag-header-foreground-color: var(--io-white);
      --ag-header-background-color: var(--io-black-1a);
      --ag-header-column-resize-handle-color: var(--io-black);
     --ag-row-border-color: #373841;
     --ag-data-color: var(--io-black-d1)
  }
  .ag-theme-quartz .ag-row-odd{
      background-color: var(--io-white);
  }
  html.dark .ag-theme-quartz-dark .ag-row-odd{
      background-color: var(--io-gray-15);
  }
  html.dark .ag-theme-quartz-dark .ag-root-wrapper{
      border: none;
  }
  .ag-theme-quartz ::-webkit-scrollbar {
      width: 5px;
  }
  .ag-theme-quartz ::-webkit-scrollbar-track {
      background: #ffffff;
  }
  .ag-theme-quartz ::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 5px;
  }
  html.dark .ag-theme-quartz-dark ::-webkit-scrollbar-thumb {
      background: #2A2B34;
      border-radius: 5px;
  }
  html.dark .ag-theme-quartz-dark ::-webkit-scrollbar-track {
      background: transparent;
  }
  .ag-theme-quartz ::-webkit-scrollbar-thumb:hover {
      background: #afafaf;
  }