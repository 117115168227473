
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: inter;
  src: url(assets/fonts/Inter.ttf);
}

@layer base {
  * {
    @apply box-border p-0 m-0 list-none outline-none font-inter;
  }
  :root {
    --io-primary: #0078d4;
    --io-black: #000000;
    --io-white: #ffffff;
    --io-orange: #f48e2a;
    --io-yellow: #ffe923;
    --io-red: #ef3a39;
    --io-green: #5cc50b;
    --io-blue: #648fff;
    --io-gray-c8: #c8c8c8;
    --io-gray-c1: #c1c5cb;
    --io-gray-f8: #f8f8f8;
    --io-gray-e9: #e9e9e9;
    --io-gray-32: #323130;
    --io-gray-33: #333333;
    --io-gray-f7: #f7f7f7;
    --io-gray-66: #666666;
    --io-gray-9d: #9d9d9d;
    --io-gray-47: #474e54;
    --io-gray-fa: #fafafc;
    --io-gray-df: #dfdfdf;
    --io-gradient-from: #44aeff;
    --io-gradient-to: #0078d4;
    --io-active-menu: #d9e1e8;
    --io--black-active-menu: #172030;
    --io-black-1a: #1a1c28;
    --io-black-1c: #1c1e29;
    --io-black-29: #292b39;
    --io-black-48: #484952;
    --io-black-15: #151722;
    --io-black-a3: #a3a4a9;
    --io-black-d1: #d1d2d4;
    --io-black-72: #727b89;
    --io-black-23: #232432;
    --num-columns: 6;
  }
  html {
    @apply text-[62.5%] max-3xl:text-[55%] max-2xl:text-[50%];
  }

  body {
    @apply max-w-full overflow-x-hidden;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}
@layer components {
  .gradient-text {
    @apply bg-gradient-to-r from-io-gradient-from from-10% to-io-gradient-to to-90% bg-clip-text text-transparent;
  }
  .gradient-bg {
    @apply bg-gradient-to-r from-io-gradient-from from-10% to-io-gradient-to to-90%;
  }
  .active-menu::before {
    @apply content-[''] w-[0.5rem] h-full bg-io-primary absolute top-0 left-0;
  }
  .step-connector-3::after {
    @apply content-[''] w-[13vw] h-[2px] bg-io-primary absolute top-[50%] left-full translate-y-[-50%] bg-opacity-10 max-2xl:w-[12.5vw];
  }
  .step-connector-4::after {
    @apply content-[''] w-[9vw] h-[2px] bg-io-primary absolute top-[50%] left-full translate-y-[-50%] bg-opacity-10 max-2xl:w-[8.5vw];
  }
  .steps-wrapper.completed .step-connector-3::after {
    @apply h-[4px] bg-opacity-100;
  }
  .steps-wrapper:last-child .step-connector-3:after {
    @apply hidden;
  }
  .steps-wrapper.completed .step-connector-4::after {
    @apply h-[4px] bg-opacity-100;
  }
  .steps-wrapper:last-child .step-connector-4:after {
    @apply hidden;
  }
  .search-icon {
    @apply bg-[url('assets/images/search.png')] bg-no-repeat bg-[2%];
  }
  .search-icon:not(:placeholder-shown) {
    @apply w-[28rem] border-io-gray-c8 dark:border-io-black-48;
  }
  .active-arrow::after {
    @apply content-[''] w-[1.2rem] h-[1.2rem] bg-transparent absolute bottom-[-75%] left-[50%] translate-x-[-50%] border-b-[1.2rem] border-b-io-white dark:border-b-io-black-15 border-t-[1.2rem] border-t-transparent border-s-[1.2rem] border-s-transparent border-e-[1.2rem] border-e-transparent;
  }
  .active-profilearrow::after {
    @apply content-[''] w-[1.2rem] h-[1.2rem] bg-transparent absolute bottom-[-40%] left-[50%] translate-x-[-50%] border-b-[1.2rem] border-b-io-white dark:border-b-io-black-15 border-t-[1.2rem] border-t-transparent border-s-[1.2rem] border-s-transparent border-e-[1.2rem] border-e-transparent;
  }
  .activemenu-bg-text .active-inner-text {
    @apply bg-gradient-to-r from-io-gradient-from from-10% to-io-gradient-to to-90% bg-clip-text text-transparent;
  }
  .activemenu-bg-text .active-inner-icon {
    @apply fill-io-gradient-to;
  }
  .modallist-container-grid > div > div {
    @apply w-[20%] max-3xl:w-[25%] max-2xl:w-[33%] max-md:w-[50%] max-sm:w-[50%];
  }
  .scrollbar-large::-webkit-scrollbar {
    @apply w-[6px];
  }
  .scrollbar-large::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  .scrollbar-large::-webkit-scrollbar-thumb {
    @apply bg-[#d9d9d9] rounded-[5px];
  }
  .scrollbar-large::-webkit-scrollbar-thumb:hover {
    @apply bg-[#afafaf];
  }
  .scrollbar-medium::-webkit-scrollbar {
    @apply w-[5px];
  }
  .scrollbar-medium::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  .scrollbar-medium::-webkit-scrollbar-thumb {
    @apply bg-[#d9d9d9] rounded-[5px];
  }
  .scrollbar-medium::-webkit-scrollbar-thumb:hover {
    @apply bg-[#afafaf];
  }
  .scrollbar-small::-webkit-scrollbar {
    @apply w-[3px];
  }
  .scrollbar-small::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  .scrollbar-small::-webkit-scrollbar-thumb {
    @apply bg-[#d9d9d9] rounded-[5px];
  }
  .scrollbar-small::-webkit-scrollbar-thumb:hover {
    @apply bg-[#afafaf];
  }
  .question-seperator{
    @apply relative
  }
  .question-seperator{
    @apply after:content-[''] after:w-[1px] after:h-[90%] after:bg-[#c9c9c9] after:absolute after:top-[50%] after:translate-y-[-50%] after:right-[-20%] after:translate-x-[-50%]
  }
}

@layer utilities {
  .login-bg {
    background: linear-gradient(
      to bottom,
      var(--io-gradient-from),
      var(--io-gradient-to)
    );
  }
  .login-pattern-bg {
    background: url("assets/images/login-pattern.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .banner-gradient {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      #ecf6f9 46%,
      #cfe3f6 60%,
      #cfe3f669 100%
    );
    z-index: 2;
  }
  .process-sidenav {
    height: calc(100vh - 6rem);
  }
  .banner-gradient-dark {
    background: #000000;
    background: linear-gradient(
      90deg,
      #1a1c28 46%,
      #1a1c28 60%,
      #1a1c2869 100%
    );
    z-index: 2;
  }
  .star-pattern {
    background-image: url("assets/images/star.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 3;
  }
  .main-area {
    height: calc(100vh - 6rem);
  }
  .side-nav{
    
    height: calc(100vh - 6rem);
  }
  .overflow-wrap-anywhere{
    overflow-wrap: anywhere;
  }
  .grid-cols-small {
    grid-template-columns: repeat(auto-fit, minmax(14.2rem, 1fr));
  }
  .border-red {
    border: 1px solid red;
  }
  .border-blue {
    border: 1px solid blue;
  }
  .border-white {
    border: 1px solid white;
  }
  .layout-wrapper.expanded .confetti canvas {
    width: calc(100% - 18.2%) !important;
    height: 100vh !important;
    margin-left: auto;
  }
  .layout-wrapper.collapsed .confetti canvas {
    width: calc(100% - 3.5%) !important;
    height: 100vh !important;
    margin-left: auto;
  }
  .or-line::before {
    content: "";
    width: 46%;
    height: 1px;
    display: block;
    background: var(--io-gray-e9);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .or-line::after {
    content: "";
    width: 46%;
    height: 1px;
    display: block;
    background: var(--io-gray-e9);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.swiper-pagination-bullet {
  width: 1rem;
  height: 1rem;
  display: block;
  background-color: var(--io-white);
  border-radius: 3.5rem;
  transition: 0.5s ease;
}
.swiper-pagination-bullet-active {
  width: 3.5rem;
}

.grid-cols-fit {
  grid-template-columns: repeat(var(--num-columns), minmax(auto, 1fr));
}
@media (max-width: 1366px) {
  :root {
    --num-columns: 5;
  }
 
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  color: #fff;
  width: 16px;
  height: 16px;
  border: 1px solid var(--io-gray-c1);
  border-radius: 2px;
  display: grid;
  place-content: center;
}
.dark input[type="checkbox"] {
  appearance: none;
  background-color: #151722;
  color: #fff;
  width: 16px;
  height: 16px;
  border: 1px solid var(--io-gray-c1);
  border-radius: 2px;
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.85rem;
  height: 0.85rem;
  transform: scale(0);
  transition: 120ms transform ease;
  box-shadow: inset 1em 1em var(--io-white);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type="checkbox"]:checked {
  background-color: var(--io-primary);
  border: 1px solid var(--io-primary);
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.cus-select__indicator-separator {
  display: none;
}
.react-select__indicator-separator {
  display: none;
}
.cus-select__value-container {
  padding-top: 0.9rem !important;
  padding-bottom: 0.9rem !important;
  padding-left: 1rem !important;
}
.cus-select__multi-value__remove {
  display: none !important;
}
.dark .cus-select__control {
  background-color: transparent !important;
  border-color: var(--io-black-48) !important;
}
.cus-select__control {
  box-shadow: none !important;
}
.cus-select__multi-value {
  background-color: transparent !important;
  position: relative;
}
.cus-select__multi-value::after {
  content: ",";
  font-size: 1.4rem;
  color: var(--io-black);
}
.dark .cus-select__multi-value::after {
  color: var(--io-white);
}
.cus-select__multi-value__label {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
.cus-select__single-value,
.cus-select__input-container  {
  font-size: 1.4rem !important;
}
.dark .cus-select__single-value {
  color: var(--io-white) !important;
}
.dark .cus-select__menu-list {
  background-color: var(--io-black-1c);
}
.cus-select__option {
  font-size: 1.4rem !important;
}
.cus-select__option--is-selected {
  background-color: var(--io-gray-df) !important;
  color: var(--io-black) !important;
}
.dark .cus-select__option {
  color: var(--io-white) !important;
}
.dark .cus-select__option--is-selected,
.dark .cus-select__option--is-focused {
  background-color: var(--io-black-23) !important;
  color: var(--io-white) !important;
}
.cus-select__menu-portal {
  z-index: 11 !important;
}
.cus-select__placeholder {
  font-size: 1.2rem;
  color: var(--io-gray-33) !important;
  background: var(--io-white);
  padding: 0 0.5rem !important;
}
.dark .cus-select__placeholder {
  color: var(--io-black-d1) !important;
  background: var(--io-black-15);
}
.cus-multi-select__placeholder {
  font-size: 1.2rem;
  color: var(--io-gray-33) !important;
  background: var(--io-white);
  padding: 0 0.5rem !important;
}
.dark .cus-multi-select__placeholder {
  color: var(--io-black-d1) !important;
  background: var(--io-black-15);
}
.select-input .cus-select__value-container {
  padding-top: 1.4rem !important;
  padding-bottom: 1.4rem !important;
  padding-left: 1rem !important;
}
.cus-multi-select__multi-value__label{
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
.cus-multi-select__menu-portal {
  z-index: 11 !important;
}
.cus-multi-select__input{
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
.dark .cus-multi-select__control {
  background-color: transparent !important;
  border-color: var(--io-black-48) !important;
}
.cus-multi-select__option{
  font-size: 1.4rem !important;
}
.dark .cus-multi-select__option {
  color: var(--io-white) !important;
}
.dark .cus-multi-select__option--is-selected,
.dark .cus-multi-select__option--is-focused {
  background-color: var(--io-black-23) !important;
  color: var(--io-white) !important;
}
.dark .cus-multi-select__menu-list {
  background-color: var(--io-black-1c) !important;
}
.dark .cus-multi-select__multi-value{
  background: var(--io-black-23) !important;
}
.dark .cus-multi-select__multi-value__label{
  color: var(--io-white) !important;
}
.dark .cus-multi-select__multi-value__remove svg{
  fill: var(--io-white) !important;
}
.cus-multi-select__indicator-separator {
  display: none;
}
.cus-multi-select__value-container {
  font-size: 1.4rem !important;
  padding-top: 1.3rem !important;
  padding-bottom: 1.3rem !important;
  padding-left: 1rem !important;
}
.cus-multi-select__control {
  box-shadow: none !important;
}
.hookform-select .cus-multi-select__value-container {
  padding-top: 1.3rem !important;
}

/* editor tools styles */
.activeIcon * {
  stroke: var(--io-primary) !important;
}
.sidebar {
  position: absolute;
  top: 0%;
  right: -6%;
  padding: 0 1rem;
  width: 40%;
  display: flex;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
}
.sidebar input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}
.sidebar input[type="range"]::-webkit-slider-runnable-track {
  height: 13px;
  background: #ccc;
  border-radius: 16px;
}
.sidebar input[type="range"]::-moz-range-track {
  height: 13px;
  background: #ccc;
  border-radius: 16px;
}
.sidebar input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 13px;
  width: 13px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid var(--io-primary);
  box-shadow: -407px 0 0 400px var(--io-primary);
}
.sidebar input[type="range"]::-moz-range-thumb {
  height: 13px;
  width: 13px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid var(--io-primary);
  box-shadow: -407px 0 0 400px var(--io-primary);
}
.tab-slider .swiper-slide {
  width: auto !important;
}
.preview-mobile-large {
  background: url(assets/images/preview-mobile-large.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.Toastify__toast-container {
  width: 32% !important;
}
.Toastify__toast--success {
  background-color: #58be83 !important;
}
.Toastify__toast--error {
  background-color: #e93838 !important;
}
.Toastify__toast--warning {
  background-color: #ec6c25 !important;
}
.loader {
  --c: no-repeat linear-gradient(var(--io-white) 0 0);
  background: var(--c), var(--c), var(--c), var(--c), var(--c), var(--c),
    var(--c), var(--c), var(--c);
  background-size: 22px 22px;
  animation: l32-1 1s infinite, l32-2 1s infinite;
}
@keyframes l32-1 {
  0%,
  100% {
    width: 75px;
    height: 75px;
  }
  35%,
  65% {
    width: 95px;
    height: 95px;
  }
}
@keyframes l32-2 {
  0%,
  40% {
    background-position: 0 0, 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%,
      100% 0, 50% 0, 50% 50%;
  }
  60%,
  100% {
    background-position: 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0,
      50% 0, 0 0, 50% 50%;
  }
}
.btn-loader {
  width: 100%;
  height: 100%;
  border: 3px solid var(--io-white);
  border-bottom-color: var(--io-gradient-to);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.btn-loader-opp {
  width: 100%;
  height: 100%;
  border: 3px solid var(--io-gradient-to);
  border-bottom-color: var(--io-white);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.react-daterange-picker .react-daterange-picker__wrapper{
  border: 1px solid var(--io-gray-c1);
  box-shadow: none;
  padding: 0.55rem 1rem;
  border-radius: 3px;
}
.react-daterange-picker__inputGroup__input{
  /* font-size: 1.4rem; */
}
.captcha-containter{
  margin-top:10px;
  color: red;
  text-align: left;
  font-size:1.4rem;
}
.cus-date-range{
  width: 100%;
  border: 1px solid var(--io-gray-c8);
  font-size: 1.6rem;
  padding: 1rem;
  border-radius: 0.5rem;
}
.rmdp-container {
  width: 100%;
}
.rmdp-range{
  background-color: var(--io-primary) !important;
}
.rmdp-week-day{
  color: var(--io-primary) !important;
}
.rmdp-arrow-container{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.rmdp-arrow{
  margin-top: 0 !important;
}
.rmdp-arrow-container:hover{
  background-color: var(--io-primary) !important;
}
.rmdp-range-hover{
  background-color: var(--io-primary) !important;
  opacity: 0.8 !important;
}
.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover{
  background-color: #44aeff !important;
  
}
.rmdp-day.rmdp-today span{
  background-color: #44aeff !important;
  
}* {
  box-sizing: border-box;
}